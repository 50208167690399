import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider, Frame } from "@shopify/polaris";
import OverlayManager from "./OverlayManager";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppProvider i18n={enTranslations}>
      <Frame>
        <OverlayManager>
          <App />
        </OverlayManager>
      </Frame>
    </AppProvider>
  </React.StrictMode>
);
